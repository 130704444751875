<script lang="ts" setup>
import { usePageMetadata } from '~/composables/usePageMetadata'
import type { AllDocumentTypes } from '~/prismicio-types'
import { components } from '~/slices'

definePageMeta({
  layout: 'dark-header',
})

const DOCUMENT_TYPE: AllDocumentTypes['type'] = 'home'
const i18n = useI18n()
const { client } = usePrismic()

const { data: page } = await useAsyncData(`${DOCUMENT_TYPE}`, () =>
  client.getSingle(DOCUMENT_TYPE))

if (!page.value)
  throw createError({ fatal: true, statusCode: 404, statusMessage: i18n.t('errors.404.statusMessage'), message: i18n.t('errors.404.message') })

usePageMetadata(page.value?.data)
</script>

<template>
  <div>
    <SliceZone v-if="page" :components="components" :slices="page.data.slices" />
  </div>
</template>
